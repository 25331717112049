<template>
    <div class="user">
        <img class="avatar" :src="user.avatar" alt="">
        <div class="text">{{ user.name }}</div>
    </div>
</template>
<script>
import user from '../../mixins/user.js'

export default {
    mixins: [
        user
    ],
    data() {
        return {
            user: {
                name: '',
                avatar: 'https://img.alicdn.com/imgextra/i3/O1CN01Mpftes1gwqxuL0ZQE_!!6000000004207-2-tps-240-240.png'
            }
        }
    },

};
</script>
<style lang="less">
.user {
    text-align: center;
    padding: 20px 0;
    background-color: #fff;
    .avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
    .text {
        margin-top: 5px;
    }
}
</style>