import util from '../libs/util.js'
export default {
    data() {
        return {
            user: {
                name: '',
                avatar: 'https://img.alicdn.com/imgextra/i3/O1CN01Mpftes1gwqxuL0ZQE_!!6000000004207-2-tps-240-240.png'
            }
        }
    },
    mounted() {
        this.user.name = util.cookies.get('username')
        this.user.avatar = util.cookies.get('avatar')
    }
}